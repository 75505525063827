import React, {createRef, useEffect, useState} from "react";
import {Socket} from "socket.io-client";
import {Scrollbars} from "react-custom-scrollbars-2";
import {ReactComponent as ShoppingCartSVG} from "../assets/svg/shopping-cart.svg";
import {ReactComponent as CloseSVG} from "../assets/svg/close.svg";

// @ts-ignore
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

export interface ProductItem {
    configurable?: boolean,
    parent?: string,
    sku: string,
    title: string,
    price: string,
    images: string[],
    description: string
}

interface ProductProps {
    extraClasses?: string,
    isProductPillVisible: boolean,
    addProductToCart: (product: ProductItem) => void
    product?: ProductItem
}

const Product = ({extraClasses, product, isProductPillVisible, addProductToCart} : ProductProps) => {
    const [isProductFullviewVisible, setIsProductFullviewVisible] = useState(false);

    let sliderRef = createRef<Slide>();

    return (
        product ? (
            <>
                <div className={`c-product--pill ${isProductPillVisible && !isProductFullviewVisible ? 'c-product--pill-visible': ''}`}>
                    <span className={"circle plus"} />
                    <div className={"c-product--image"}
                         style={{
                             backgroundImage: `url('${product.images[0]}')`
                         }}

                         onClick={() => {
                             setIsProductFullviewVisible(true)
                         }}
                    >
                    </div>
                </div>

                {/* Product overlay*/}
                <div className={`c-product--overlay ${isProductFullviewVisible ? 'c-product--overlay-visible': ''}`}>
                    <div className={"c-product--slideshow"}>
                        <div className={"o-layout u-columns-12"}>
                            <div className={"u-colspan-10"}>
                                <Slide easing="ease" ref={sliderRef}>
                                    {product.images.slice(0, 3).map((item, index) => {
                                        return (
                                            <div className="c-product--slideshow-item">
                                                <div style={{'backgroundImage': `url(${item})`}} key={index}/>
                                            </div>
                                        )
                                    })}
                                </Slide>
                            </div>
                            <div className={"u-colspan-2"}>
                                {product.images.slice(0, 3).map((item, index) => {
                                    return (<img key={`img-${index}`} src={item} onClick={() => {
                                        sliderRef.current.goTo(index)
                                    }} />)
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={"o-layout u-columns-12"}>
                        <span className={"c-product--title u-colspan-10@desk"}>{product.title}</span>
                        <div className={"c-product--description u-colspan-10@desk"}>
                            <Scrollbars className={"c-product--description-scrollbar"}>
                                {product.description}
                            </Scrollbars>
                        </div>
                        <div className={"c-product--controls"}>
                            <button className={"c-product--add e-button--primary"}
                                    onClick={() => {
                                        addProductToCart(product);
                                        setIsProductFullviewVisible(false);
                                    }}>
                                <ShoppingCartSVG className={"c-product--cart-icon"}/>
                                VOEG TOE
                            </button>
                            <button className={"c-product--close e-button--secondary"}
                                    onClick={() => {
                                        setIsProductFullviewVisible(false)
                                    }}>
                                <CloseSVG className={"c-product--close-icon"} />
                                SLUITEN
                            </button>
                        </div>
                    </div>
                </div>
            </>
        ) : (<></>)
    )
};

export default Product;
