import React, {useEffect, useState} from "react";
import {Socket} from "socket.io-client";

interface ChatProps {
    extraClasses?: string
    visible: boolean,
    socket?: Socket,
    setIsNameformVisible: (isVisible: boolean) => void,
    realUsername: string
}

interface Message {
    username: string,
    message: string,
    id: string
}

const Chat = ({extraClasses, visible, socket, setIsNameformVisible, realUsername} : ChatProps) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [newMessage, setNewMessage] = useState<Message>();
    const [removedMessage, setRemovedMessage] = useState("");
    const [socketId, setSocketId] = useState("");
    const MAX_MESSAGES = 3;

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (socket && socketId !== socket.id) {
            setSocketId(socket.id);
            socket?.emit('add user', realUsername);
        }

        socket?.emit('new message', currentMessage)
        setCurrentMessage("")
        event.preventDefault();
    }


    useEffect(() => {
        socket?.on('delete message', (id: string) => {
            setRemovedMessage(id);
        });

        socket?.on('new message', (message: Message) => {
            setNewMessage(message);
        })
    }, [socket])

    useEffect(() => {
        if (newMessage) {
            const sliceLength = Math.max(0, messages.length - MAX_MESSAGES)
            setMessages([...messages, newMessage].slice(sliceLength))
        }
    }, [newMessage])

    useEffect(() => {
        if (removedMessage && removedMessage.length > 0) {
            let newMessagesArray : Message[] = [];
            for (const index in messages) {
                const message = messages[index];
                if (message.id !== removedMessage) {
                    newMessagesArray.push(message);
                }
            }
            setMessages(newMessagesArray)
            setRemovedMessage("");
        }
    }, [removedMessage])

    return (
        <div className={`c-chat ${extraClasses} ${!visible ? 'c-chat--hide': ''}`}>
            <div className="c-chat--outer">
                <div className="c-chat--messages-wrapper">
                    <div className="c-chat--messages">
                        {messages.map((message: Message) => {
                            return (
                                <div>{message.username}: {message.message}</div>
                            )
                        })}
                    </div>
                </div>

                <form className="c-chat--form" onSubmit={onSubmit}>
                    <input className={"c-chat--input"} type="text"
                           placeholder="Voeg een reactie toe..." required autoComplete={"off"}
                           value={currentMessage}
                           onChange={(event) => {
                               setCurrentMessage(event.target.value);
                           }}
                           onClick={(event: React.FormEvent) => {
                               if (!realUsername || realUsername.length === 0) {
                                   setIsNameformVisible(true);
                               }
                           }}
                    />
                </form>
            </div>
        </div>
    )
};

export default Chat;
