import React from 'react';
import Homepage from "./pages/Homepage";

function App() {
  return (
    <div className="o-layout c-app">
        <Homepage />
    </div>
  );
}

export default App;
