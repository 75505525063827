import {ProductItem} from "../components/Product";

const keyCartId = 'cartId';
export const shopUrl = "https://cors-proxy.handpickedlabs.nl/https://www.johnbeerens.com";

export function getCartId() {
    return new Promise((resolve, reject) => {
        let cartId = localStorage.getItem(keyCartId);
        if (!cartId || cartId.length === 0) {
            post("mutation {createEmptyCart}")
                .then(r => r.json())
                .then(response => {
                    if (response.data.createEmptyCart) {
                        localStorage.setItem(keyCartId, response.data.createEmptyCart);
                        cartId = localStorage.getItem(keyCartId);
                        resolve(cartId)
                    } else {
                        reject('Failed to get cartId')
                    }
                });
        } else {
            resolve(cartId);
        }
    })
}


export function getCart(retry?: boolean) {
    return new Promise((resolve, reject) => {
        getCartId()
            .then((cartId) => {
            post(`{cart(cart_id: "${cartId}") {
                items {
                  id
                  product {
                    name
                    sku
                    image {
                      url
                    }
                      price_range {
                        minimum_price {
                          final_price {
                            value
                          }
                        }
                      }
                  }
                  quantity
                }
                prices {
                  grand_total {
                    value
                    currency
                  }
                }
                total_quantity
          }
        }`).then(r => r.json())
                .then(response => {
                    if (response.errors && response.errors.length > 0 && !retry) {
                        localStorage.setItem(keyCartId, "");
                        getCart(true).then(resolve);
                    } else {
                        resolve(response.data.cart);
                    }
                });
        })
    });
}

export function addToCart(productItem: ProductItem, quantity: number) {
    if (productItem.configurable && productItem.parent) {
        return addConfigurableProduct(productItem.sku, productItem.parent, quantity);
    } else {
        return addSimpleProduct(productItem.sku, quantity);
    }
}

function addConfigurableProduct(sku: string, parentSku: string, quantity: number) {

    return new Promise((resolve, reject) => {
        getCartId().then((cartId) => {
            post(`
mutation {
  addProductsToCart(
    cartId: "${cartId}"
    cartItems: [
      {
        quantity: ${quantity}
        parent_sku: "${parentSku}"
        sku: "${sku}"
      }
    ]
  ) {
    cart {
      items {
        id
        product {
          name
          sku
        }
        quantity
      }
    }
  }
}`).then(r => r.json())
                .then(response => {
                    // console.log('addToCart:', response)
                    resolve(response)
                });
        })
    });
}

function addSimpleProduct(sku: string, quantity: number) {

    return new Promise((resolve, reject) => {
        getCartId().then((cartId) => {
            post(`
mutation {        
    addSimpleProductsToCart(
    input: {
      cart_id: "${cartId}"
      cart_items: [
        {
          data: {
            quantity: ${quantity}
            sku: "${sku}"
          }
        }
      ]
    }
  ) {
    cart {
      items {
        id
        product {
          sku
          stock_status
        }
        quantity
      }
    }
  }
}`).then(r => r.json())
                .then(response => {
                    // console.log('addToCart:', response)
                    resolve(response)
                });
        })
    });
}


export function removeProduct(id: string) {

    return new Promise((resolve, reject) => {
        getCartId().then((cartId) => {
            post(`
mutation {
  removeItemFromCart(
    input: {
      cart_id: "${cartId}",
      cart_item_id: ${id}
    }
  )
 {
  cart {
    items {
      id
      product {
        name
      }
      quantity
    }
    prices {
      grand_total{
        value
        currency
      }
    }
  }
 }
}`).then(r => r.json())
                .then(response => {
                    resolve(response)
                });
        })
    });
}

function post(query: string) {
    return fetch(`${shopUrl}/graphql`, {
        method: 'POST',
        // mode: "same-origin",
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'Origin': shopUrl
        },
        body: JSON.stringify({query: query})
    });
}
