import {ReactComponent as ShoppingCartSVG} from "../assets/svg/shopping-cart.svg";
import { ReactComponent as BinSVG } from "../assets/svg/bin.svg";
import React, {useState} from "react";
import {getCartId, removeProduct, shopUrl} from "../Api/Magento";
import {Scrollbars} from "react-custom-scrollbars-2";

export interface CartData {
    items: CartProductItem[],
    total_quantity: number,
    prices: {
        grand_total: {
            currency: string,
            value: number
        }
    }
}

export interface CartProductItem {
    id: string,
    quantity: number,
    product: {
        sku: string,
        name: string,
        price: string,
        image: {
            url: string
        }
        price_range: {
            minimum_price: {
                final_price: {
                    value: number
                }
            }
        }
    }
}

interface CartProps {
    cartData?: CartData,
    update: () => void
}

const Cart = ({cartData, update} : CartProps) => {
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const items = cartData?.items;

    return (
        <>
            <div className={"c-cart--control o-layout u-columns-12"}>
                <div className={"c-cart--icon-outer"}  onClick={() => {
                    setIsOverlayVisible(!isOverlayVisible)
                }}>
                    <ShoppingCartSVG className={"c-cart--icon"} />
                    {items && items.length > 0 ? (
                        <div className={"c-cart--icon-indicator"} />
                    ) : ''}
                </div>

                <div className={`c-cart--overlay ${isOverlayVisible ? 'c-cart--overlay-visible' : ''}`}>
                    <div className={"c-cart--item o-layout o-layout--gap-tiny"}>
                        <div className={"c-cart--total-item u-colspan-12"}>
                            {cartData?.total_quantity} item{cartData && cartData?.total_quantity > 1 || cartData&& cartData?.total_quantity === 0 ? 's': ''}</div>
                        <div className={"c-cart--total-price u-colspan-12"}>€ {cartData?.prices.grand_total.value}</div>
                    </div>

                    {!items || items.length === 0 ? (
                        <div className={"c-cart--item o-layout o-layout--gap-tiny"}>
                          <div className={"u-colspan-24"}>
                            Nog geen producten
                          </div>
                        </div>
                    ) : ''}

                    <div className={"c-cart--item-list"}>
                        <Scrollbars className={"c-cart--item-list-scrollbar"}>
                        {items?.map((item) => {
                            return (
                                <div className={"c-cart--item o-layout o-layout--gap-tiny"} key={item.product.sku}>
                                    <div className={"c-cart--item-image u-colspan-4 u-colspan-2@lap"}>
                                        <img src={item.product.image.url} />
                                    </div>
                                    <div className={"c-cart--item-title u-colspan-17 u-colspan-20@lap"}>
                                        <div>{item.product.name}</div>
                                        <div>{item.quantity}x € {item.product.price_range.minimum_price.final_price.value}</div>
                                    </div>
                                    <div className={"c-cart--item-remove u-colspan-2"}><BinSVG onClick={() => {
                                        removeProduct(item.id)
                                            .then(update)
                                    }}/></div>
                                </div>
                            )
                        })}
                        </Scrollbars>
                    </div>

                    <div className={"o-layout o-layout--gap-tiny"}>
                        <button className={"e-button--primary c-cart--checkout"}
                        onClick={() => {
                            getCartId()
                                .then((cartId: any) => {
                                    window.location.href = `${shopUrl}/cartRedirect?cartId=${cartId}`;
                                })
                        }}>
                            Afrekenen
                        </button>

                        <button className={"e-button--secondary c-cart--close"} onClick={() => {
                            setIsOverlayVisible(false)
                        }}>
                            Sluiten
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Cart;
