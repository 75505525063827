import React, {useEffect} from "react";

const Player = () => {
    useEffect(() => {
        // @ts-ignore
        var myPlayer = dacast("d15807f3-2f15-bec1-366b-841d69e4ac03-vod-68f78fba-9fba-a893-adf4-83ad31b991cc\n", 'player', {
            width: '100%',
            height: '100%'
        });
    }, [])

    return (
        <div className={"c-player--frame"}>
            <div id={"player"} className={"c-player--iframe"} ></div>

        </div>
    )
};

export default Player;
