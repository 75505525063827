import React, {createRef, useEffect, useRef, useState} from "react";
import io, {Socket} from "socket.io-client";

import Player from "../components/Player";
import Chat from "../components/Chat";


import { ReactComponent as ChatOpenSVG } from "../assets/svg/chat.svg";
import { ReactComponent as ChatCloseSVG } from "../assets/svg/chatx.svg";
import { ReactComponent as ShoppingCartSVG } from "../assets/svg/shopping-cart.svg";
import { ReactComponent as CloseSVG } from "../assets/svg/close.svg";

import Logo from "../assets/img/logo.png";
import Product, {ProductItem} from "../components/Product";
import Cart, {CartData} from "../components/Cart";
import {addToCart, getCart} from "../Api/Magento";

const Homepage = () => {
    const [isChatVisible, setIsChatVisible] = useState(true);
    const [isProductVisible, setIsProductVisible] = useState(true);
    const [isNameformVisible, setIsNameformVisible] = useState(false);
    const [socket, setSocket] = useState<Socket>();
    const [cart, setCart] = useState<CartData>();
    const [currentProduct, setCurrentProduct] = useState<ProductItem>();

    const [tempUsername, setTempUsername] = useState("");
    // Only set after pushing set
    const [realUsername, setRealUsername] = useState("");

    const nameInputRef = useRef<HTMLInputElement>(null);

    const updateCart = () => {
        getCart().then((cart: any) => {
            console.log(cart);
            setCart(cart as CartData);
        })
    }

    useEffect(() => {
        updateCart()
    }, []);

    useEffect(() => {
        setSocket(io("https://jb-backend.handpickedlabs.nl"));
    }, []);

    useEffect(() => {
        if (tempUsername) {
            socket?.emit('add user', tempUsername);
        } else if (realUsername) {
            socket?.emit('add user', realUsername);
        }

        socket?.on('info', (data: any) => {
            // console.log(data);

            if (data.currentProduct !== null) {
                setCurrentProduct(data.currentProduct as ProductItem)
            }
        })

        socket?.on('show product', (product: ProductItem) => {
            setCurrentProduct(product);
            setIsProductVisible(true);
        })

        socket?.on('hide product', () => {
            setIsProductVisible(false);
        })
    }, [socket]);

    useEffect(() => {
        if (isNameformVisible) {
            nameInputRef?.current?.focus();
        }
    }, [isNameformVisible]);

    const toggleChat = () => {
        setIsChatVisible(!isChatVisible);
    };

    const addProductToCart = (productItem: ProductItem) => {

        addToCart(productItem, 1)
            .then(() => getCart())
            .then((cart: any) => {
                setCart(cart as CartData);
            })
    };

    return (
        <>
            <div className={`c-join-popup ${isNameformVisible ? 'c-join-popup--show': ''}`}>
                <div className={"c-join-popup--box"}>
                    <div className={"c-join-popup--title"}>Naam instellen</div>
                    <div>Vul hier je naam in om reacties te kunnen versturen.</div>
                    <form onSubmit={(event) => {
                        setRealUsername(tempUsername);
                        setIsNameformVisible(false);
                        socket?.emit('add user', tempUsername);
                        event.preventDefault();
                    }}>
                    <input type="text" placeholder={"Vul hier je naam in..."}
                           className={"c-join-popup--set-name"}
                           onChange={(event) => {
                               setTempUsername(event.target.value);
                           }}
                           ref={nameInputRef}
                    />
                        <div className={"c-join-popup--controls"}>
                            <button className={"c-join-popup--save"}
                                    type={"submit"}
                            >Naam instellen</button>
                            <button className={"c-join-popup--close"} onClick={() => {
                                setIsNameformVisible(false)
                            }}>Sluiten</button>
                        </div>
                    </form>
                </div>
            </div>
            <Player />
            <div className={"c-controls"}>

                <Product product={currentProduct} isProductPillVisible={isProductVisible} addProductToCart={addProductToCart} />

                <div className={"c-controls--container o-layout u-columns-24"}>
                    <div className={"c-controls--main u-colspan-21 u-colspan-22@lap"}>
                        <div className={"c-controls--top"}>
                            <div className={"c-controls--logo-title"}>
                                <img src={Logo} className={"c-controls--logo"} />
                                <div className={"c-controls--title"}>
                                    John Beerens <span className={"c-controls--live"}>LIVE</span>
                                </div>
                            </div>
                        </div>

                        <Chat extraClasses={""} visible={isChatVisible} socket={socket}
                              setIsNameformVisible={setIsNameformVisible}
                              realUsername={realUsername}/>
                    </div>

                    <div className={"c-controls--sidebar u-colspan-3 u-colspan-2@lap"}>
                        <div className={"c-controls--sidebar-top"}>
                            <Cart cartData={cart} update={updateCart} />
                        </div>

                        {isChatVisible ? (
                            <ChatCloseSVG className={"c-controls--sidebar-item c-controls--chat-toggle"} onClick={toggleChat} />
                        ) : (
                            <ChatOpenSVG className={"c-controls--sidebar-item  c-controls--chat-toggle"} onClick={toggleChat} />
                        )}
                    </div>
                </div>
            </div>

            <div className={"c-overlay"}></div>
        </>
    )
}

export default Homepage;
